@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700");
@import url("https://fonts.googleapis.com/css?family=Roboto:400,300,500,700");
@import url("//fonts.googleapis.com/earlyaccess/notosanskr.css");

body {
	font-size: 14px;
	font-family: "Noto Sans KR", "IBM Plex Sans", sans-serif;
	font-weight: 400 !important;
}

.swal-modal {
	font-family: "Noto Sans KR", "IBM Plex Sans", sans-serif;
}
.swal-title {
	font-size: 22px;
}
.swal-button-container .swal-button {
	font-size: 14px;
}
.swal-button-container .swal-button--confirm {
	background-color: #5156be;
	border-color: #5156be;
}
.swal-button-container .swal-button--confirm:hover {
	background-color: #4549a2;
	border-color: #414598;
}
.swal-button-container .swal-button--confirm:focus {
	-webkit-box-shadow: 0 0 0 0.15rem rgb(107 111 200 / 50%);
	box-shadow: 0 0 0 0.15rem rgb(107 111 200 / 50%);
}
select.form-control {
	appearance: auto;
}
.pointer {
	cursor: pointer;
}

.react-select__menu {
	z-index: 10 !important;
}

.card-deck {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
}
.card-deck .card {
	margin-bottom: 15px;
}
@media (min-width: 576px) {
	.card-deck {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-ms-flex-flow: row wrap;
		flex-flow: row wrap;
		margin-right: -15px;
		margin-left: -15px;
	}
	.card-deck .card {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-flex: 1;
		-ms-flex: 1 0 0%;
		flex: 1 0 0%;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		margin-right: 15px;
		margin-bottom: 0;
		margin-left: 15px;
	}
}

.actions {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.card > .actions,
.card-body .actions {
	position: absolute;
	right: 15px;
	z-index: 2;
	top: 18px;
}

.card-subtitle:not(:last-child) {
	margin-bottom: 2rem;
}

.filepond--root {
	min-height: 76px;
	cursor: pointer;
}
.filepond--drop-label.filepond--drop-label label {
	cursor: pointer;
}
